"use client"; // This is a client-side component

import { useEffect } from "react";

export default function GoogleTagManager({ gtmId }) {
  useEffect(() => {
    const handleLoad = () => {
      const gtmScript = document.createElement("script");
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
      gtmScript.async = true;
      document.head.appendChild(gtmScript);
    };

    // Listen for the load event to lazy-load GTM
    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("load", handleLoad); // Cleanup the event listener
    };
  }, [gtmId]);

  return null; // This component doesn't render any UI
}
