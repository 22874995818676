"use client"; // This is a client-side component

import { useEffect, useState } from "react";
import Script from "next/script";

export default function GoogleAds({ adId }) {
  const [conversionTracked, setConversionTracked] = useState(false); // State to track if conversion is already fired

  useEffect(() => {
    const initGoogleAds = () => {
      if (typeof window.gtag === "function" && !conversionTracked) {
        // Google Ads conversion tracking code
        window.gtag("event", "conversion", {
          send_to: `AW-${adId}`,
          event_callback: function () {
            console.log("Google Ads conversion tracked successfully.");
            setConversionTracked(true); // Mark conversion as tracked
          },
        });
      } else if (conversionTracked) {
        console.log("Conversion already tracked.");
      } else {
        console.error("Google Ads tracking script not yet loaded.");
      }
    };

    // Attach the form or event handler logic here if needed
    window.addEventListener("load", initGoogleAds);

    return () => {
      window.removeEventListener("load", initGoogleAds); // Cleanup listener
    };
  }, [adId, conversionTracked]); // Only run when adId or conversionTracked changes

  return (
    <>
      {/* Load the Google Ads Script */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=AW-${adId}`}
        strategy="afterInteractive" // Ensures script is loaded after the page becomes interactive
        async
      />

      {/* Initialize the gtag function once the script is loaded */}
      <Script
        id="google-ads-init"
        strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-${adId}');
        `}
      </Script>
    </>
  );
}
